




















import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class FilePickerComponent extends Vue {

	@Prop() readonly value!: File
	@Prop() readonly label!: string
	@Prop() readonly message!: string
	@Prop() readonly acceptExtensions!: string
	@Prop({default: 5}) readonly maxSize!: number
	@Prop({default: true}) readonly messageSize!: boolean
	@Prop({default: ""}) readonly name?: String

	file: File | null = this.value

	get fileComputed() {
		return this.value ? this.value : null
	}

	rules = [
        (v: any) => !!v || 'File is required'
	]

	created() {
		this.$parent.$on('clear', this.clear)
		this.$parent.$on('validate', this.validate)

	}

	clear() {
		this.file = null
	}

	validate() {
		// @ts-ignore
		return this.$refs.fileInput.validate()
	}

	set fileComputed(v: any) {
		this.file = v
		this.$emit('input', this.file)
	}

	handleFilesValidated(result: any, files: any) {
		console.log("handleFilesValidated")
		console.log(result)
		console.log(files)
	}

	handleFilesChanged(files: any) {
		console.log("handleFilesChanged", files)
		this.fileComputed = files[0]
	}
}
